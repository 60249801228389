@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	scroll-padding-top: 100px;
}

html, body {
	max-width: 100%;
    overflow-x: hidden;
	overflow-y: overlay;
	-webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
	width: 5px;
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background: lightgray;
	height:20px;
	border-radius: 10px;
}
  
::-webkit-scrollbar-corner{
	background: lightgray;
	border-radius: 10px;
}

@media only screen and (max-width: 767px) {
    #mappa {
        width: 100%;
        height: 250px;
    }

	#skills {
		padding-top: 0;
		margin-top: 0;
	}

	#nav, #buttonNav {
		display: none;
	}

	.timeline-item[data-aos="fade-left"] {
		transform: translateX(-100px);
	}
}

.timeline-section{
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
	min-height: 100vh;
	padding: 20px 15px;
}

.timeline-items{
	max-width: 1000px;
	height: auto;
	margin:auto;
	display: flex;
	flex-wrap: wrap;
	position: relative;
}

.timeline-title {
	border-bottom: 0px;
	padding-bottom: 0px;
}

.timeline-items::before{
	content: '';
	position: absolute;
	width: 2px;
	height: 100%;
	background-color: #2f363e;
	left: calc(50% - 1px);
}
.timeline-item{
	margin-bottom: 10px;
	width: 100%;
	position: relative;
}

@media only screen and (max-width: 767px) {
	.timeline-item {
		margin-bottom: 20px;
	}
}

.timeline-item:last-child{
	margin-bottom: 0;
}
.timeline-item:nth-child(odd){
    padding-right: calc(50% + 30px);
	text-align: right;
}
.timeline-item:nth-child(even){
    padding-left: calc(50% + 30px);
}
.timeline-dot{
	height: 16px;
	width: 16px;
	background-color: rgb(22 163 74);
	position: absolute;
	left: calc(50% - 8px);
	border-radius: 50%;
	top:10px;
}
.timeline-date{
	font-size: 18px;
	color: rgb(22 163 74);
	margin:6px 0 15px;
}
.timeline-content{
    background-color: rgb(31 41 55);
	padding: 30px;
	border-radius: 5px;
}
.timeline-content h3{
    font-size: 20px;
	color: #ffffff;
	margin:0 0 10px;
	text-transform: capitalize;
	font-weight: 500;
}
.timeline-content p{
    color: #c8c8c8;
	font-size: 16px;
	font-weight: 300;
	line-height: 22px;
}

.timeline-item:nth-child(odd) .hr_line{
	width: 20%;
	text-align: right;
	margin: 15px 0 15px 0;
	margin-left: auto
}

@media(max-width: 767px){
	.timeline-item:nth-child(odd) .hr_line{
		margin-left: 0;
		width: 15%;
	}
	.timeline-items::before{
		left: 7px;
	}
	.timeline-item:nth-child(odd){
		padding-right: 0;
		text-align: left;
	}
	.timeline-item:nth-child(odd),
	.timeline-item:nth-child(even){
		padding-left: 37px;
	}
	.timeline-dot{
		left:0;
	}
}

.cookieBannerContainer {
	display: none;
	position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 40;
    background-color: rgba(0, 0, 0, 0.6);
}

.cookieBanner {
	align-items: baseline;
    background: rgb(17 24 39);
    color: white;
    display: flex;
    flex-wrap: wrap;
    left: 0px;
    position: fixed;
    width: 100%;
    z-index: 40;
    bottom: 0px;
	justify-content: center;
  	align-items: center; 
}

.bannerLeftColumn {
	flex: 1 0 300px;
    margin: 15px;
}

.bannerRightColumn {
	display: flex;
	flex-direction: column;
}

.modal-video-body {
	max-width: 1024px;
}

.twoLines {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}

.filterGray {
	filter: invert(100%) sepia(63%) saturate(227%) hue-rotate(299deg) brightness(116%) contrast(101%);
}
.backdrop {
	background-color: rgba(0,0,0,0.5);
}

@media only screen and (min-width: 320px) {
	#aboutDesc {
		margin-left: 5px;
	}
}

@media only screen and (min-width: 370px) {
	#aboutDesc {
		margin-left: 15px;
	}
}

@media only screen and (min-width: 430px) {
	#aboutDesc {
		margin-left: 20px;
	}
}

@media only screen and (min-width: 500px) {
	#aboutDesc {
		margin-left: 25px;
	}
}

@media only screen and (min-width: 768px) {
	#aboutDesc {
		margin-left: 0;
	}
}

@media only screen and (min-width: 768px) {
	#frameMappa {
		height: 85%;
	}
}

@media only screen and (min-width: 1024px) {
	#frameMappa {
		height: 90%;
	}
}

#message {
	height: 78px;
}

@media only screen and (max-width: 767px) {
	#message {
		height: 120px;
	}
}